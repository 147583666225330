// ----- HomePage Copy ------
import {PHONE} from "./Constants";

export const HomePage_IntroDescription1 = 'My mission as a physician over the last 20 years has been to provide quality care by never rushing through issues, and by truly listening to my patients. Recently there has been increased pressure on traditional practices to see more patients in less time thus making my approach to medicine very difficult. In an effort to correct this I have decided to shift from a traditional practice to a concierge practice. As a result, I will be able to take my time with patients, make myself more available, monitor hospitalizations, and work with you to improve your wellness with our team approach. It all starts with you making that first step.';

export const HomePage_IntroDescription2 = 'To better explain why I chose concierge medicine, please read my article, The Concierge Revolution, as published in the November issue of Old Naples News.';

export const HomePage_FAQ1_Question = 'What is concierge?';
export const HomePage_FAQ1_Answer = 'Modern day medicine fixates on physicians rushing through as many appointment visits as possible, putting courtesy and quality care aside. With insurance companies’ seemingly endless restrictions and complexity, the majority of each physician’s day is dedicated to dealing with paperwork.';
export const HomePage_FAQ1_Resource = '/benefits'

export const HomePage_FAQ2_Question = 'Why concierge?';
export const HomePage_FAQ2_Answer = 'In a traditional office practice, I have a minimum of 3500 patients. However, in this setting, I limit my patient volume to around 300 patients. Limiting my overall patient volume frees me to dedicate more time to each patient’s individual appointment or concerns.';
export const HomePage_FAQ2_Resource = '/benefits';

export const HomePage_FAQ3_Question = 'Do I still need health insurance?';
export const HomePage_FAQ3_Answer = 'The only time that you will need your insurance will be to go to the lab, imaging or to see your specialists.'

// ----- TeamPage Copy ------
export const TeamPage_CristinaDescription1 = 'As a daughter of two physicians, the world of medicine was introduced to me at an early age. My parents taught me the importance of the time proven principles of good communication with patients, the formation of a true patient-physician bond and going that extra mile. I was fortunate to have learned this from them and it has been my foundation ever since.';
export const TeamPage_CristinaDescription2 = 'Born in Brooklyn, I went to Brooklyn College and graduated magna cum laude with a BA in psychology. I then obtained my medical degree from SUNY Downstate at Brooklyn, an experience which I will always cherish. After completing a Pediatrics Internship at Cornell at New York Hospital, I finished my Internal Medicine Residency at Staten Island University Hospital. During this time I was chosen as one of their Medical Chief Residents.';
export const TeamPage_CristinaDescription3 = 'I have been in Naples for almost 20 years practicing at different locations, including 6 years in the urgent care realm. Because of this I have added an urgent care approach to my practice when needed. As an internist, dealing with multiple complex medical conditions is the usual for me. Optimizing the treatment of these conditions is what we will work together on.';
export const TeamPage_CristinaDescription4 = 'Wellness and prevention has been a strong portion of my mission, and I am involved in community lectures and articles. Knowledge truly does empower people and it is important to continue this. Heart disease and stroke prevention as well as ongoing cancer screening have been a primary focus for me. Prevention does save lives and it is vital for my patients to take an active role in their health.';

export const TeamPage_DebbiDescription = 'Debbi is originally from Alexandria, Virginia. She was raised in Clearwater, Florida until moving to Naples in 1986. She has 2 sons who are her pride and joy. She has a special place in her heart for animals of all varieties. Fendi, Chloe, Zena, and Misty are her constant and dedicated companions. In her free time, Debbi enjoys tennis, hiking, paddle boarding, boating, and biking.';

// ----- BenefitsPage Copy ------
export const BenefitsPage_ConciergeExplained1 = 'Modern day medicine fixates on physicians rushing through as many appointment visits as possible, putting courtesy and quality care aside. With insurance companies’ seemingly endless restrictions and complexity, the majority of each physician’s day is dedicated to dealing with paperwork, billing issues, and insurance companies. Quality compromised, the concierge approach has emerged as the clear solution. Concierge medicine embodies ideal patient care: longer, more individualized visits in a relaxed setting where each issue is addressed thoroughly and properly, prioritizing the patient above the billing. Payment is replaced with one annual membership fee, eliminating any hassles with all visits throughout the year. The focus is on quality patient care, not billing, insurance rules or unnecessary paperwork.';
export const BenefitsPage_ConciergeExplained2 = 'In a traditional office practice, I have a minimum 3500 of patients. However, in this setting, I limit my patient volume to around 300 patients. Limiting my overall patient volume frees me to dedicate more time to each patient’s individual appointment or concerns. Additionally, I am no longer confined to an office setting; now I can monitor hospital visits and keep in close contact with the hospitalist team. The key is not only to be your physician, but to form a true physician-patient bond aimed at your health and wellness.';
export const BenefitsPage_ConciergeExplained3 = 'To better explain why I chose concierge medicine, please read my article, The Concierge Revolution, as published in the November issue of Old Naples News.';

export const BenefitsPage_BenefitsIncludedList = [
    'Personalized care in a relaxed setting',
    'Access to Dr. Sciavolino-Day 365 days a year in person and via telephone/email/text',
    'Emphasis on wellness and prevention',
    'All medical visits and annual wellness exam throughout the year are included in the annual membership fee',
    'Monitoring of all hospital visits via electronic chart review throughout the day',
    'Coordination of referrals and care with your specialists',
    'Use of modern electronic medical records',
    'Online video consultation for seasonal and vacationing patients while away from Naples',
];

export const BenefitsPage_Joining1 = 'To inquire further or to set up an interview visit with Dr. Sciavolino-Day, contact her either by phone at ' + PHONE + ' or by email at ';
export const BenefitsPage_Joining2 = 'It all starts with you.'