import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Typography } from "antd";
import { Image, SectionTitle, SectionBody, Section } from './common/Section';
import { Centered } from "./common/Layout";
import {
    TeamPage_CristinaDescription1,
    TeamPage_CristinaDescription2,
    TeamPage_CristinaDescription3,
    TeamPage_CristinaDescription4,
    TeamPage_DebbiDescription,
} from "../data/Copy";
import './TeamPage.scss';
import CristinaHeadshot from '../images/headshot-blue.jpg';
import DebbiHeadshot from '../images/debbi-headshot.jpg';

const TeamSection = ({ name, title, imgSrc, description }) => (
    <Row>
        <Col xs={{ span: 18, offset: 3 }} lg={{span: 6, offset: 0}}>
            <Image imgSrc={imgSrc} alt={name} />
        </Col>
        <Col xs={{span: 24, offset: 0}} lg={{span: 16, offset: 2}}>
            <Centered>
                <Typography.Title className='team-section-name'>{name}</Typography.Title>
                <Typography.Title level={3} className='team-section-title'>{title}</Typography.Title>
            </Centered>
            <SectionBody>{description}</SectionBody>
        </Col>
    </Row>
);

const TeamPage = () => (
    <React.Fragment>
        <Row>
            <Col xs={{span: 18, offset: 3}} lg={{span: 10, offset: 7}}>
                <div className='cristina-headshot-wrapper'>
                    <Image imgSrc={CristinaHeadshot} alt='Dr. Cristina Sciavolino-Day' />
                </div>
            </Col>
        </Row>
        <Section>
            <SectionTitle>Cristina Sciavolino-Day, M.D.</SectionTitle>
            <Divider />
            <SectionBody>{TeamPage_CristinaDescription1}</SectionBody>
            <SectionBody>{TeamPage_CristinaDescription2}</SectionBody>
            <SectionBody>{TeamPage_CristinaDescription3}</SectionBody>
            <SectionBody>{TeamPage_CristinaDescription4}</SectionBody>
        </Section>
        <Section>
            <SectionTitle>Meet the Team</SectionTitle>
            <Divider />
            <Section>
                <TeamSection
                    name='Debbi Nevius'
                    title='Office Administrator'
                    imgSrc={DebbiHeadshot}
                    description={TeamPage_DebbiDescription}
                />
            </Section>
        </Section>
    </React.Fragment>
);

export default TeamPage;